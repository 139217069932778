import {
    PERMISSION_MANAGEMENT_PREFIX_PATH, SCHOOL_MANAGEMENT_PREFIX_PATH,
    SUBSCRIPTION_MANAGEMENT_PREFIX_PATH,
    USER_MANAGEMENT_PREFIX_PATH,
    ERROR_LOG_MANAGEMENT_PREFIX_PATH, LESSON_MANAGEMENT_PREFIX_PATH,
    SUBSCRIPTION_ITEM_FAMILY_MANAGEMENT_PREFIX_PATH
} from "./route.constant";

export const NAV_ITEM_TYPE_TITLE = 'title'
export const NAV_ITEM_TYPE_COLLAPSE = 'collapse';
export const NAV_ITEM_TYPE_ITEM = 'item';

export const NURSING_DOMAIN = process.env.REACT_APP_NURSING_DOMAIN
export const NURSING_LINK = `${NURSING_DOMAIN}/dashboard`

export const ROUTES_AUTHENTICATION = {
    SIGN_IN: "/sign-in",
    FORGOT_PASSWORD: "/forgot-password"
};

export const ROUTES_DASHBOARD = {
    HOME: "/home"
};

export const ROUTES_USER_MANAGEMENT = {
    USER_LIST: `${USER_MANAGEMENT_PREFIX_PATH}/users-list`,
    USER_DETAILS: `${USER_MANAGEMENT_PREFIX_PATH}/users-list/:userId/user-details`
};

export const ROUTES_SUBSCRIPTION_MANAGEMENT = {
    SUBSCRIPTION_PLANS: `${SUBSCRIPTION_MANAGEMENT_PREFIX_PATH}/subscription-plans`,
    PLAN_DETAILS: `${SUBSCRIPTION_MANAGEMENT_PREFIX_PATH}/subscription-plans/:planId/plan-details`,
};

export const ROUTES_PERMISSION_MANAGEMENT = {
    PERMISSIONS_LIST: `${PERMISSION_MANAGEMENT_PREFIX_PATH}/permissions-list`,
    PERMISSION_DETAILS: `${PERMISSION_MANAGEMENT_PREFIX_PATH}/permissions-list/:permissionId/permission-details`,
};

export const ROUTES_ERROR_LOG_MANAGEMENT = {
    ERROR_LOG_LIST: `${ERROR_LOG_MANAGEMENT_PREFIX_PATH}/error-log-list`,
    ERROR_LOG_DETAILS: `${ERROR_LOG_MANAGEMENT_PREFIX_PATH}/error-log-list/:errorLogId/error-log-details`,
}
export const ROUTES_SCHOOL_MANAGEMENT = {
    SCHOOL_LIST: `${SCHOOL_MANAGEMENT_PREFIX_PATH}/school-list`,
    SCHOOL_LIST_DETAILS: `${SCHOOL_MANAGEMENT_PREFIX_PATH}/school-list/:schoolId/school-details`,
};

export const ROUTES_LESSON_VIDEO_TRACKER_STATISTIC_MANAGEMENT = {
    LESSON_VIDEO_TRACKER_STATISTIC_LIST: `${LESSON_MANAGEMENT_PREFIX_PATH}/lesson-video-tracker-statistic-list`,
}

export const ROUTES_SUBSCRIPTION_ITEM_FAMILY_MANAGEMENT = {
    SUBSCRIPTION_ITEM_FAMILY_LIST: `${SUBSCRIPTION_ITEM_FAMILY_MANAGEMENT_PREFIX_PATH}/subscription-item-family-list`,
    SUBSCRIPTION_ITEM_FAMILY_DETAIL: `${SUBSCRIPTION_ITEM_FAMILY_MANAGEMENT_PREFIX_PATH}/subscription-item-family-list/:id/details`,
}

export const ROUTES_EDITOR = {
    PAGES: "/pages",
    EDITOR: "/pages/:id"
};
