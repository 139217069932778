export const ROOT = '/';
export const USER_MANAGEMENT_PREFIX_PATH = "/user-management";
export const SUBSCRIPTION_MANAGEMENT_PREFIX_PATH = "/subscription-management";
export const PERMISSION_MANAGEMENT_PREFIX_PATH = "/permission-management";
export const ERROR_LOG_MANAGEMENT_PREFIX_PATH = "/error-log-management";
export const SCHOOL_MANAGEMENT_PREFIX_PATH = "/school-management";
export const LESSON_MANAGEMENT_PREFIX_PATH = "/lesson-management";
export const SUBSCRIPTION_ITEM_FAMILY_MANAGEMENT_PREFIX_PATH = "/subscription-item-family-management";


