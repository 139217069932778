import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setUser, initialState } from 'store/auth/userSlice'
import * as AuthService from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import useQuery from './useQuery'
import { isAdmin } from "../commonUtils";

export const getUserAuthorities = rolesArray => rolesArray.map(role => role.title);

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const {token, signedIn} = useSelector((state) => state.auth.session)

    const signIn = async ({email, password}) => {
        try {
            const resp = await AuthService.apiSignIn({email, password})

            if (resp.data) {

                const {user} = resp.data;

                if (user?.roles && !isAdmin(user?.roles)) {

                    throw new Error("You are not authorized");
                }

                const {accessToken: token} = resp.data
                await dispatch(onSignInSuccess(token))
                if (user) {
                    await dispatch(setUser({
                        ...resp.data.user,
                        avatar: '',
                        authority: getUserAuthorities(user.roles)
                    }))
                }
                const redirectUrl = await query.get(REDIRECT_URL_KEY)
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        try {
            handleSignOut()
        } catch (errors) {
            handleSignOut()
        }
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signOut
    }
}

export default useAuth
